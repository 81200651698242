<template>
  <div class="g02-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import g02Service from '@/services/g02Service'
import auth from '@/common/auth'
import tenderService from '@/services/tenderService'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G02TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '工程形象进度月统计明细表1',
        columns: [
          {
            label: '序号',
            dataKey: 'engineeringListCode',
            width: '50'
          },
          {
            label: '项目类别',
            dataKey: 'parentEngineeringListName',
            width: 80,
            autoMerge: true
          },
          {
            label: '项目名称',
            dataKey: 'engineeringListName',
            width: 200,
            autoMerge: true
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          }
        ]
      },
      defaultDataList: [],
      engineeringListList: [],
      tenderStatList: [],
      tenders: [],
      engineeringListMap: {},
      otherTenderStats: [],
      reportDate: '',
      engineeringType: this.$route.query.engineeringType || '土建'
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      this.$refs.mySpread.exportExcel('标段工程形象进度月统计明细表1.xlsx')
    },
    generateMapData () {
      const totalColumn = {
        label: '本月完成',
        children: [
          {
            label: '工程量',
            dataKey: 'total_currentMonthStatNum',
            width: 90
          },
          {
            label: '占月计划（%）',
            dataKey: 'total_currentMonthStatPercent',
            width: 90
          }
        ]
      }
      this.spreadInfo.columns.push(totalColumn)

      this.tenders.forEach(tender => {
        const column = {
          label: `${tender.tenderName}`,
          children: [
            {
              label: '工程量',
              dataKey: `${tender.tenderName}_currentMonthStatNum`,
              width: 90
            },
            {
              label: '占月计划（%）',
              dataKey: `${tender.tenderName}_currentMonthStatPercent`,
              width: 90
            }
          ]
        }
        this.spreadInfo.columns.push(column)
      })

      this.engineeringListMap = {}
      this.tenderStatList.filter(item => item.tenderName === 'total').forEach(item => {
        this.engineeringListMap[`${item.engineeringListCode}`] = item
      })
      console.log(this.engineeringListMap)

      this.tenderStatList.forEach(item => {
        this.engineeringListMap[`${item.engineeringListCode}`][`${item.tenderName}_currentMonthStatNum`] = item.currentMonthStatNum
        this.engineeringListMap[`${item.engineeringListCode}`][`${item.tenderName}_currentMonthStatPercent`] = item.currentMonthStatPercent
      })

      for (let key of Object.keys(this.engineeringListMap)) {
        const item = this.engineeringListMap[key]
        if (item.parentId === 0) {
          item.sortId = 0
        }
        this.defaultDataList.push(item)
      }

      this.defaultDataList.sort((a, b) => a.sortId - b.sortId).map((item, index) => {
        item.index = index
        return item
      })

      this.$loading().close()
    },
    getTenders () {
      tenderService.list({ pageIndex: 1, pageSize: 200 }, { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          this.tenders = res.data.data.filter(tender => tender.engineeringType === this.engineeringType)
          this.getData()
        })
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      this.spreadInfo.columns = [
        {
          label: '序号',
          dataKey: 'engineeringListCode',
          width: '50'
        },
        {
          label: '项目类别',
          dataKey: 'parentEngineeringListName',
          width: 200,
          autoMerge: true
        },
        {
          label: '项目名称',
          dataKey: 'engineeringListName',
          width: 200
        },
        {
          label: '单位',
          dataKey: 'unit',
          width: 90
        }
      ]
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      const year = parseInt(this.reportDate.substr(0, 4))
      const month = parseInt(this.reportDate.substr(4, 2))
      g02Service.getDetailTenderStat(year, month, auth.getUserInfo().projectGuid, this.engineeringType)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data

            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getTenders()
  }
}
</script>

<style scoped lang="scss">
.g02-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
